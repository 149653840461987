import React from "react"

import LoginForm from "../components/authentication/loginForm"
import Layout from "../components/layout/layout"

const IndexPage = () => (
  <Layout>
    <LoginForm />
  </Layout>
)

export default IndexPage
