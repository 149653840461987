/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useEffect } from "react"
import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css"
import "../../sass/site.scss"

import Header from "./header"
import Footer from "./footer"
import RedirectIfNotSignedIn from "../authentication/redirectIfNotSignedIn"

const Layout = ({ children }: { children: React.Component }) => {
  return (
    <>
      <RedirectIfNotSignedIn />
      <div className="layout-container bg-primary text-white">
        <div className="layout-header">
          <Header />
        </div>
        <div className="layout-body">
          <main className="container">{children}</main>
        </div>
        <div className="layout-footer">
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Layout
