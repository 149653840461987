import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import { navigate } from "gatsby"

const Header = () => {
  const {
    logout,
    state: { isAuth },
  } = useContext(AuthContext)

  const logoutClicked = () => {
    navigate("/")
    logout()
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span
          style={{
            textTransform: "uppercase",
            letterSpacing: "6px",
            fontSize: "0.8em",
            fontWeight: 600,
          }}
        >
          <span className="text-primary bg-white p-2">Guardian Counsel</span>
          <span> Law Office</span>
        </span>
      </div>

      <div className="nav navbar-nav navbar-right">
        {isAuth && (
          <>
            <button className="btn btn-success my-2 my-sm-0" onClick={logoutClicked}>
              Logout
            </button>
          </>
        )}
      </div>
    </nav>
  )
}

export default Header
