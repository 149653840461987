import React, { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useEffect } from "react"
import { navigate } from "gatsby"

const RedirectIfNotSignedIn = () => {
  const { checkSignedIn } = useContext(AuthContext)

  useEffect(() => {
    const runCheck = async () => {
      const isSignedIn = await checkSignedIn()

      console.log("isSignedIn?", isSignedIn)

      if (!isSignedIn) {
        navigate("/")
      }
    }

    runCheck()
  }, [])

  return <></>
}

export default RedirectIfNotSignedIn
