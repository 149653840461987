import React from "react"

const Footer = () => (
  <>
    <div className="container font-weight-lighter small" style={{ display: "flex", justifyContent: "space-between" }}>
      <span>© 2020 Guardian Counsel Law Office</span>
      <span>3800 American Blvd. West Suite 1500, Bloomington, MN 55431</span>
      <span>(952) 317-2177</span>
      <span>law@guardiancounsel.com</span>
    </div>
  </>
)

export default Footer
